import Vue from 'vue';
import VueRouter from 'vue-router';
import Footer from '../components/Footer';
import HomePage from '../views/HomePage.vue';
import NotFoundPage from '../views/NotFoundPage.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'HomePage',
        // component: HomePage,
        components: {
            default: HomePage,
            footer: Footer
        },
    },
    {
        path: '/kor',
        name: 'HomePageKor',
        // component: HomePage,
        components: {
            default: HomePage,
            footer: Footer
        },
    },
    {
        path: '/eng',
        name: 'HomePageEng',
        // component: HomePage,
        components: {
            default: HomePage,
            footer: Footer
        },
    },
    {
        path: '/jp',
        name: 'HomePageJp',
        // component: HomePage,
        components: {
            default: HomePage,
            footer: Footer
        },
    },
    {
        path: '/chi',
        name: 'HomePageChi',
        // component: HomePage,
        components: {
            default: HomePage,
            footer: Footer
        },
    },
    // {
    //     path: '/kor/about',
    //     name: 'AboutPage',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ '../views/AboutPage.vue'),
    // },
    {
        path: '*',
        name: 'NotFoundPage',
        // component: () =>
        //     import(/* webpackChunkName: "about" */ '../views/NotFoundPage.vue'),
        components: {
            default: NotFoundPage,
            footer: Footer
        },
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
