<template>
    <div class="popup-wrap" v-if="activePopup">
        <div class="popup-dim"></div>
        
        <div class="popup-base">
            <div class="popup-scroll">
                <img :src="require(`@/assets/img/popup-award-2023.jpg`)" />

                <button class="close-award" @click="closePopup">
                    close
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-award-2023',
    
    props : [
        'activePopup',
    ],

    mounted() {
        //
    },

    methods: {
        closePopup: function () {
            this.$emit('closePopup');
        }
    }
};
</script>
