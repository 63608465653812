<template>
    <footer id="footer" class="footer" :class="showBannerAlledu ? 'ft-bn--show' : ''">
        <div class="footer__container">
            <div class="footer__logo">
                <img src="../assets/img/footer-logo.png" alt="AES Logo" class="footer__logo--image" />
            </div>

            <div class="footer__text">
                Copyright© 2023 AES Inc. All Rights Reserved.
            </div>
        </div>
        
        <!-- ft-bn -->
        <div class="ft-bn" v-if="showBannerAlledu">
            <button class="ft-bn__close" @click.stop="showBannerAlledu = false">
                <img src="../assets/img/btn-close.png" alt="" />
            </button>
                
            <a href="https://www.alledu.shop/" target="_blank" class="ft-bn__link">
                <div class="ft-bn__logo"><img src="../assets/img/ft-bn-logo-alledu.gif" alt="alledu shop" /></div>
                <p v-html="$t('ftBn.title')" class="ft-bn__title">아시아 에듀테크 제품<br /> 카탈로그 서비스</p>
                <div class="ft-bn__cart"><img src="../assets/img/ft-bn-cart.gif" alt="" /></div>

                <div class="ft-bn__go">
                    <span class="ft-bn__go-text">{{$t('ftBn.go')}}</span>
                    
                    <span class="ft-bn__go-arrow">
                        <object type="image/svg+xml" :data="require(`@/assets/svg/ft-bn-arrow.svg`)" class=""></object>
                    </span>
                </div>
            </a>

            <!-- <button class="ft-bn__close" @click="closeBannerAlledu">
                <object type="image/svg+xml" :data="require(`@/assets/svg/ft-btn-close.svg`)" class="ft-bn__close-svg"></object>
            </button> -->
        </div>
        <!-- // ft-bn -->
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    // props: {
    //     msg: String,
    // },

    data: function() {
        return {
            showBannerAlledu: true, // 우측 플로팅 배너 - alledu SHOP
        };
    },

    mounted() {
        //
    },

    methods: {
        // alledu SHOP Close 버튼 클릭
        closeBannerAlledu() {
            console.log('click');
            this.showBannerAlledu = false;
        }
    }
};
</script>
