import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Meta from 'vue-meta';
import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
    config: {
        id: 'G-87V40NTM3B',  // Google Analytics의 Tracking ID
        params: {
            send_page_view: false, // default : true
        },
    },
}, router);

Vue.config.productionTip = false;

Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',  // 변화시키는 구분 값
    refreshOnceOnNavigation: true
});

new Vue({
    created() {
        AOS.init();
    },
    router,
    store,
    i18n,
    // RenderAfterDocument Event:'render-event'name must correspond to vue-config.js
    mounted () {
        document.dispatchEvent(new Event('render-event'));
    },
    render: (h) => h(App)
}).$mount('#app');

