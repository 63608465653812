<template>
    <!-- home --> 
    <div class="home" v-cloak>        
        <!-- overlay -->
        <transition name="overlay--fade">
            <div
                v-if="isOpenNavDrawer"
                class="overlay"
                @click="closeNavDrawer"               
            >
                <div class="overlay__scrim"></div>
            </div>
        </transition>
        <!-- // overlay -->

        <!-- overlay -->
        <transition name="overlay--fade">
            <div class="overlay overlay--popup" v-if="isPopupAward">
                <div class="popup-wrap">
                    <div class="popup">
                        <div class="popup__close">
                            <button 
                                class="close__button"
                                @click="isPopupAward = !isPopupAward"
                            >
                                <i>
                                    <img :src="require(`@/assets/img/popup-award-close.gif`)" />
                                </i>
                            </button>
                        </div>                  

                        <div class="popup__body">
                            <div class="popup__content">
                                <div class="title"><img :src="require(`@/assets/img/popup-award-title.png`)" /></div>
                                
                                <div class="group">
                                    <div class="type type--platinum">Platinum</div>

                                    <ul class="list">
                                        <li class="list__item">
                                            <span class="text">Hyundaeyongosa Co., Ltd</span>
                                            <span class="text bul">Speaking Tok</span>
                                        </li>
                                        <li class="list__item">
                                            <span class="text">GAKUSHO CO.,LTD.</span>
                                            <span class="text bul">デジタルドリル(中学版)</span>
                                        </li>
                                        <li class="list__item">
                                            <span class="text">aichinese-retech</span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="group">
                                    <div class="type type--gold">Gold</div>

                                    <ul class="list">
                                        <li class="list__item">
                                            <span class="text">Mingaku Co., Ltd.</span>
                                            <span class="text bul">教育機関向けオンライン自習室専門システム</span>
                                        </li>
                                        <li class="list__item">
                                            <span class="text">VISANG EDUCATION</span>
                                            <span class="text bul">ELiF</span>
                                        </li>
                                        <li class="list__item">
                                            <span class="text">i-guan</span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="group">
                                    <div class="type type--silver">Silver</div>

                                    <ul class="list">
                                        <li class="list__item">
                                            <span class="text">Benesse Corporation.</span>
                                            <span class="text bul">Speaking Quest(スピーキングクエスト）</span>
                                        </li>
                                        <li class="list__item">
                                            <span class="text">MEDIOPIA TECH Corp.</span>
                                            <span class="text bul">Virtual CAMPUS</span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="group">
                                    <div class="type type--bronze">Bronze</div>

                                    <ul class="list">
                                        <li class="list__item">
                                            <span class="text">SWEMPIRE Co., Ltd.</span>
                                            <span class="text bul">Blockchain service platform</span>
                                        </li>
                                        <li class="list__item">
                                            <span class="text">With The World. Inc</span>
                                            <span class="text bul">中高大学生のための社会問題解決型グローバルオンライン授業</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="overlay__scrim"></div>
            </div>
        </transition>
        <!-- // overlay -->

        <!-- popup-wrap -->        
        <!-- <modal-award2022 :activePopup="false" @closePopup="closePopup"></modal-award2022> -->
        <!-- <modal-award2022 :activePopup="activePopup" @closePopup="closePopup"></modal-award2022> -->
        <!-- // popup-wrap -->

        <!-- popup-wrap -->        
        <modal-award :activePopup="false" @closePopup="closePopup"></modal-award>
        <!-- // popup-wrap -->

        <!-- <modal-award :activePopup="activePopup" @closePopup="closePopup"></modal-award> -->

        <!-- popup-wrap -->  <!-- :activePopup="activePopup2023" -->      
        <modal-award-2023 :activePopup="false" @closePopup="activePopup2023 = false"></modal-award-2023>
        <!-- // popup-wrap -->

        <!-- popup-wrap -->  
        <modal-award-2024 :activePopup="false" @closePopup="closePopup"></modal-award-2024>
        <!-- // popup-wrap -->

        <!-- n-section -->
        <section class="n-section n-section__top--bg">
            <div class="n-section__content n-section__top--visual">
                <!-- n-topbar -->
                <header class="n-topbar">
                    <!-- logo --> 
                    <div class="logo n-topbar__logo">
                        <span class="logo__space">
                            <img src="../assets/img/logo.png" alt="AES - AIA EDTECH SUMMIT" class="logo__img" />
                        </span>
                    </div>
                    <!-- // logo -->
        
                    <!-- menu --> 
                    <button 
                        @click="toggleNavDrawer" 
                        class="n-btn n-topbar__menu"
                    >
                        <i aria-hidden="true" class="n-icon n-icon-menu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.109 10.109" aria-hidden="true" class="n-icon-menu__svg">
                                <path d="M0,10.109V8.424H10.109v1.685ZM0,5.9V4.212H10.109V5.9ZM0,1.685V0H10.109V1.685Z" />
                            </svg>
                        </i>
                    </button>
                    <!-- // menu -->

                    <!-- navigation-drawer -->
                    <nav 
                        class="navigation-drawer navigation-drawer--fixed" 
                        :class="{ 'navigation-drawer--open': isOpenNavDrawer }"
                    >
                        <button 
                            v-if="isOpenNavDrawer"
                            class="navigation-drawer__close"
                            @click="closeNavDrawer"
                        >
                            <i aria-hidden="true" class="n-icon n-icon-close">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.346 10.346" class="close__svg">
                                    <path d="M1651.087,42.112l-4.234,4.234-.937-.943,4.228-4.23-4.228-4.234.937-.939,4.234,4.232L1655.316,36l.947.939-4.236,4.234,4.236,4.23-.947.943Z" transform="translate(-1645.917 -36)" />
                                </svg>
                            </i>
                        </button>
                
                        <ul class="menu">
                            <!-- <li class="menu__item"><button class="menu__button">ABOUT AES</button></li> -->
                            <li class="menu__item"><a href="#aes" class="menu__button">ABOUT</a></li>
                            <li class="menu__item"><a href="#value" class="menu__button">VALUE</a></li>
                            <li class="menu__item"><a href="#activities" class="menu__button">ACTIVITIES</a></li>
                            <li class="menu__item"><a href="#organizations" class="menu__button">ORGANIZATIONS</a></li>
                            <li class="menu__item"><a href="#award" class="menu__button">AWARD</a></li>
                            <li class="menu__item"><a href="#contact" class="menu__button">CONTACT</a></li>
                        </ul>

                        <!-- language -->
                        <div class="n-select n-select--lang" :data-value="langValue" :data-list="langList">
                            <div class="selector" @click="toggleLang()">
                                <div class="label-wrap">
                                    <div class="label-wrap__content">
                                        <div class="label" :class="{ 'label-expanded' : visible }">
                                            <span>{{ langValue }}</span>
                                        </div>

                                        <div class="arrow" :class="{ expanded : visible }"></div>
                                    </div>
                                </div>

                                <div :class="{ hidden : !visible, visible }">
                                    <ul>
                                        <li 
                                            :class="{ current : item === langValue }" 
                                            v-for="item in langList" 
                                            v-bind:key="item" 
                                            @click="selectLang(item)"
                                        >
                                            {{ item }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    <!-- // language -->
                    </nav>
                <!-- // navigation-drawer -->
                </header>
                <!-- // n-topbar -->

                <!-- visual --> 
                <div class="visual">
                    <p class="visual__text1"><img src="../assets/img/visual-text.png" alt="ASIA EDTECH SUMMIT" class="visual__text1-img" /></p>
                    <p class="visual__text2" v-html="$t('message')"></p>
                </div>
                <!-- // visual -->

                <!-- mouse-wrap --> 
                <div class='mouse-wrap'>
                    <div class='mouse'>
                        <span class='scroll-down'></span>
                    </div>
                </div>
                <!-- // mouse-wrap -->
            </div>
        </section>
        <!-- // n-section -->

        <!-- n-section -->
        <section class="n-section n-section--about-bg" id="aes">
            <div class="n-section__content n-section__about">
                <div 
                    class="main-title"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                >
                    <h2 class="main-title__h">ABOUT AES</h2>
                </div>

                <div class="about">
                    <div 
                        class="about__bg"
                        data-aos="fade-right"
                        data-aos-delay="500"
                        data-aos-duration="1500"
                    
                    ></div>

                    <p 
                        class="about__text"
                        data-aos="fade-left"
                        data-aos-delay="500"
                        data-aos-duration="1500"
                    >
                        {{$t('about')}}
                    </p>
                </div>

                <div class="main-title-2 main-title-2--space">
                    <h3 
                        class="main-title-2__h"
                        data-aos="fade"
                        data-aos-delay="0"
                        data-aos-duration="1000"
                    >
                        History</h3>
                </div>

                <!-- history --> 
                <div class="history">
                    <!-- history__box -->
                    <div class="history__box history__bg history__bg--06" data-aos="fade-up" data-aos-duration="500" v-if="true">
                        <div class="history-list">
                            <div class="history-list__box">
                                <div class="history-list__date">
                                    2023.11
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202311')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date">
                                    09
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202309')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date">
                                    07
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202307')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date">
                                    04
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202304')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // history__box -->

                    <!-- history__box -->
                    <div class="history__box history__bg history__bg--05" data-aos="fade-up" data-aos-duration="500">
                        <div class="history-list">
                            <div class="history-list__box">
                                <div class="history-list__date">
                                    2022.11
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202211-2')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date no-bul">
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202211')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date">
                                    10
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202210')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date">
                                    09
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202209')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // history__box -->

                    <!-- history__box -->
                    <div class="history__box history__bg history__bg--04" data-aos="fade-up" data-aos-duration="500">
                        <div class="history-list">
                            <div class="history-list__box">
                                <div class="history-list__date">
                                    2021.11
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202111-2')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date no-bul">
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202111')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date">
                                    09
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202109')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // history__box -->

                    <!-- history__box -->
                    <div class="history__box history__bg history__bg--03" data-aos="fade-up" data-aos-duration="500">
                        <div class="history-list">
                            <div class="history-list__box">
                                <div class="history-list__date">
                                    2020.11
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202011-2')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date no-bul">
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.202011')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // history__box -->

                    <!-- history__box -->
                    <div class="history__box history__bg history__bg--02" data-aos="fade-up" data-aos-duration="500">
                        <div class="history-list">
                            <div class="history-list__box">
                                <div class="history-list__date">
                                    2019.11
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.201911')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date">
                                    05
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.201905')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // history__box -->

                    <!-- history__box -->
                    <div class="history__box history__bg history__bg--01" data-aos="fade-up" data-aos-duration="500">
                        <div class="history-list">
                            <div class="history-list__box">
                                <div class="history-list__date">
                                    2018.11
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.201811')}}</p>
                                </div>
                            </div>

                            <div class="history-list__box">
                                <div class="history-list__date">
                                    08
                                </div>

                                <div class="history-list__item">
                                    <p class="history-list__text">{{$t('history.201808')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // history__box -->
                </div>
                <!-- // history -->
            </div>
        </section>
        <!-- // n-section -->

        <!-- n-section -->
        <section class="n-section n-section__core n-section--core-bg" id="value">
            <div class="n-section__content n-section__core-image">
                <div
                    class="main-title"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                >
                    <h2 class="main-title__h">AES CORE VALUE</h2>
                </div>

                <div class="logo-aes-wrap">
                    <div class="logo-aes">
                        <div 
                            data-aos="flip-left" 
                            data-aos-duration="1000"
                            data-aos-delay="350"
                        >
                            <img src="../assets/img/logo-aes.png" alt="" class="logo-aes__image" />
                        </div>
                    </div>
                </div>

                <div 
                    class="core-value" 
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="2000"
                >
                    <div class="core-value__box">
                        <div class="core-value__icon">
                            <img src="../assets/img/core-icon01.png" alt="" class="core-value__image" />
                        </div>

                        <div class="core-value__title" v-html="$t('core.title01')"></div>
                    </div>

                    <div class="core-value__box">
                        <div class="core-value__icon">
                            <img src="../assets/img/core-icon02.png" alt="" class="core-value__image" />
                        </div>

                        <div class="core-value__title" v-html="$t('core.title02')"></div>
                    </div>

                    <div class="core-value__box">
                        <div class="core-value__icon">
                            <img src="../assets/img/core-icon03.png" alt="" class="core-value__image" />
                        </div>

                        <div class="core-value__title" v-html="$t('core.title03')"></div>
                    </div>

                    <div class="core-value__box">
                        <div class="core-value__icon">
                            <img src="../assets/img/core-icon04.png" alt="" class="core-value__image" />
                        </div>

                        <div class="core-value__title" v-html="$t('core.title04')"></div>
                    </div>
                </div>
            </div>
        </section>
        <!-- // n-section -->

        <!-- n-section -->
        <section class="n-section" id="activities">
            <div class="n-section__content n-section__activities">
                <div
                    class="main-title"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                >
                    <h2 class="main-title__h">AES MAIN ACTIVITIES</h2>
                </div>

                <div class="activities" data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
                    <div class="activities__box activities__box01" v-html="$t('activities.title01')">
                    </div>

                    <div class="activities__box activities__box02" v-html="$t('activities.title02')">                        
                    </div>

                    <div class="activities__box activities__box03" v-html="$t('activities.title03')">
                    </div>

                    <div class="activities__box activities__box04" v-html="$t('activities.title04')">
                    </div>

                    <div class="activities__box activities__box05" v-html="$t('activities.title05')">
                    </div>

                    <div class="activities__box activities__box06" v-html="$t('activities.title06')">
                    </div>
                </div>
            </div>
        </section>
        <!-- // n-section -->

        <!-- n-section -->
        <section class="n-section n-section--organizations-bg" id="organizations">
            <div class="n-section__content n-section__organizations">
                <div
                    class="main-title"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                >
                    <h2 class="main-title__h">ORGANIZATIONS</h2>
                </div>

                <swiper class="swiper" :options="swiperOption" data-aos="fade" data-aos-delay="500" data-aos-duration="2000">
                    <div class="swiper-pagination" slot="pagination"></div>

                    <swiper-slide>
                        <!-- org-swiper -->
                        <div class="org-swiper">
                            <div class="org-swiper__top">
                                <!-- org-swiper__people -->
                                <div class="org-swiper__people">
                                    <img src="../assets/img/organizations-ko-people.png" alt="" class="org-swiper__people--image" />
                                </div>
                                <!-- // org-swiper__people -->

                                <!-- org-swiper__info --> 
                                <div class="org-swiper__info">
                                    <div class="org-swiper__namebox">
                                        <div class="org-swiper__name">Kilho Lee</div>
                                        <div class="org-swiper__co">Co-chairman</div>
                                    </div>

                                    <div class="org-swiper__text">
                                        Chairman, Korea Edu Tech Industry Association(KETIA)<br />
                                        CEO, T-ime Education  C&P Co., Ltd.
                                    </div>

                                    <div class="org-swiper__logo">
                                        <img src="../assets/img/organizations-ko-logo.png" alt="" />
                                    </div>
                                </div>
                            <!-- // org-swiper__info -->
                            </div>

                            <!-- org-swiper__logos -->
                            <div class="org-swiper__logos">
                                <img src="../assets/img/organizations-ko-logos-m.png" alt="" class="org-swiper__logos--image org-swiper__logos--m" />
                                <img src="../assets/img/organizations-ko-logos-t.png" alt="" class="org-swiper__logos--image org-swiper__logos--t" />
                                <img src="../assets/img/organizations-ko-logos-d.png" alt="" class="org-swiper__logos--image org-swiper__logos--d" />
                                <img src="../assets/img/organizations-ko-logos-xd.png" alt="" class="org-swiper__logos--image org-swiper__logos--xd" />
                            </div>
                            <!-- // org-swiper__logos -->
                        </div>
                        <!-- // org-swiper -->
                    </swiper-slide>

                    <swiper-slide>
                        <!-- org-swiper -->
                        <div class="org-swiper">
                            <div class="org-swiper__top">
                                <!-- org-swiper__people -->
                                <div class="org-swiper__people">
                                    <img src="../assets/img/organizations-ja-people.png" alt="" class="org-swiper__people--image" />
                                </div>
                                <!-- // org-swiper__people -->

                                <!-- org-swiper__info --> 
                                <div class="org-swiper__info">
                                    <div class="org-swiper__namebox">
                                        <div class="org-swiper__name">Toru Kishida</div>
                                        <div class="org-swiper__co">Co-chairman</div>
                                    </div>

                                    <div class="org-swiper__text">
                                        Chairman of the Co-Presidency, AES Global<br />
                                        Chairman, Japan Online Training and Education Industry 
                                        Association(JOTEA)<br />
                                        Chairman, NetLearning Holdings Inc.<br />
                                    </div>

                                    <div class="org-swiper__logo">
                                        <img src="../assets/img/organizations-ja-logo.png" alt="" />
                                    </div>
                                </div>
                            <!-- // org-swiper__info -->
                            </div>

                            <!-- org-swiper__logos -->
                            <div class="org-swiper__logos">
                                <img src="../assets/img/organizations-ja-logos-m.png" alt="" class="org-swiper__logos--image org-swiper__logos--m" />
                                <img src="../assets/img/organizations-ja-logos-t.png" alt="" class="org-swiper__logos--image org-swiper__logos--t" />
                                <img src="../assets/img/organizations-ja-logos-d.png" alt="" class="org-swiper__logos--image org-swiper__logos--d" />
                                <img src="../assets/img/organizations-ja-logos-xd.png" alt="" class="org-swiper__logos--image org-swiper__logos--xd" />
                            </div>
                            <!-- // org-swiper__logos -->
                        </div>
                        <!-- // org-swiper -->
                    </swiper-slide>

                    <swiper-slide>
                        <!-- org-swiper -->
                        <div class="org-swiper">
                            <div class="org-swiper__top">
                                <!-- org-swiper__people -->
                                <div class="org-swiper__people">
                                    <img src="../assets/img/organizations-ch-people.png" alt="" class="org-swiper__people--image" />
                                </div>
                                <!-- // org-swiper__people -->

                                <!-- org-swiper__info --> 
                                <div class="org-swiper__info">
                                    <div class="org-swiper__namebox">
                                        <div class="org-swiper__name">Ai Shunkwang</div>
                                        <div class="org-swiper__co">Co-chairman</div>
                                    </div>

                                    <div class="org-swiper__text">
                                        Chairman, Retech Technology Co., Ltd.
                                    </div>

                                    <div class="org-swiper__logo">
                                        <img src="../assets/img/organizations-ch-logo.png" alt="" />
                                    </div>
                                </div>
                            <!-- // org-swiper__info -->
                            </div>

                            <!-- org-swiper__logos -->
                            <div class="org-swiper__logos">
                                <img src="../assets/img/organizations-ch-logos-m.png" alt="" class="org-swiper__logos--image org-swiper__logos--m" />
                                <img src="../assets/img/organizations-ch-logos-t.png" alt="" class="org-swiper__logos--image org-swiper__logos--t" />
                                <img src="../assets/img/organizations-ch-logos-d.png" alt="" class="org-swiper__logos--image org-swiper__logos--d" />
                                <img src="../assets/img/organizations-ch-logos-xd.png" alt="" class="org-swiper__logos--image org-swiper__logos--xd" />
                            </div>
                            <!-- // org-swiper__logos -->
                        </div>
                        <!-- // org-swiper -->    
                    </swiper-slide> 

                    <swiper-slide>
                        <!-- org-swiper -->
                        <div class="org-swiper">
                            <div class="org-swiper__top">
                                <!-- org-swiper__people -->
                                <div class="org-swiper__people">
                                    <img src="../assets/img/organizations-vn-people.png" alt="" class="org-swiper__people--image" />
                                </div>
                                <!-- // org-swiper__people -->

                                <!-- org-swiper__info --> 
                                <div class="org-swiper__info">
                                    <div class="org-swiper__namebox">
                                        <div class="org-swiper__name">Nguyen Tien Luan</div>
                                        <div class="org-swiper__co">Co-chairman</div>
                                    </div>

                                    <div class="org-swiper__text">
                                        Chairman, Nguyen Trai University
                                    </div>

                                    <div class="org-swiper__logo vn">
                                        <img src="../assets/img/organizations-vn-logo.png" alt="" />
                                    </div>
                                </div>
                            <!-- // org-swiper__info -->
                            </div>

                            <!-- org-swiper__logos -->
                            <div class="org-swiper__logos">
                                <img src="../assets/img/organizations-vn-logos-m.png" alt="" class="org-swiper__logos--image org-swiper__logos--m" />
                                <img src="../assets/img/organizations-vn-logos-t.png" alt="" class="org-swiper__logos--image org-swiper__logos--t" />
                                <img src="../assets/img/organizations-vn-logos-d.png" alt="" class="org-swiper__logos--image org-swiper__logos--d" />
                                <img src="../assets/img/organizations-vn-logos-xd.png" alt="" class="org-swiper__logos--image org-swiper__logos--xd" />
                            </div>
                            <!-- // org-swiper__logos -->
                        </div>
                        <!-- // org-swiper -->    
                    </swiper-slide>                   
                </swiper>
            </div>
        </section>
        <!-- // n-section -->

        <!-- n-section -->
        <section class="n-section n-section--award-bg" id="award">
            <div class="n-section__content n-section__award">
                <div
                    class="main-title"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                >
                    <h2 class="main-title__h">AES GLOBAL AWARD</h2>
                </div>

                <!-- ahistory -->
                <div class="ahistory">
                    <!-- ahistory__section -->
                    <div class="ahistory__section" data-aos="fade-up" data-aos-duration="1000" v-if="true">
                        <div class="ahistory__title">
                            <div class="ahistory__year">2023</div>
                            <div class="ahistory__ytxt">4th AES Global Award</div>
                        </div>

                        <div class="ahistory__lists">
                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--platinum">Platinum</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">SYWORKS Corp.</div>
                                        <div class="ahistory__cproduct">AI Smart Support System powered by Honeycomb</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Digital Knowledge Co., Ltd.</div>
                                        <div class="ahistory__cproduct">Daily Learning Project</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--gold">Gold</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Alpaco Co., Ltd.</div>
                                        <div class="ahistory__cproduct">Introduction to Alpaco Learning Center</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">T-IME Education C&P, KETIA</div>
                                        <div class="ahistory__cproduct">It offers innovative educational product catalog</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Mathmaji Co.,Ltd</div>
                                        <div class="ahistory__cproduct">Mathmaji : AI Math App based on Japanese Curriculum</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">BacBon Limited</div>
                                        <div class="ahistory__cproduct">Revolutionising Education:BacBon School</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--silver">Silver</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Firb</div>
                                        <div class="ahistory__cproduct">Implement a customized LMS</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">DABIDA Corporation</div>
                                        <div class="ahistory__cproduct">ICT Convergence-Intelligent Platform ‘GeniClass’</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">OnePath Education Co., LTD.</div>
                                        <div class="ahistory__cproduct">Taiwan K12 EduTech–OneClass</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Mingaku Co.,Ltd / Colorful Gakusha</div>
                                        <div class="ahistory__cproduct">"NANDE" : The service to visualize students thinking process</div>
                                        <!-- <div class="ahistory__cproduct-type2">: 答えを教えず、答えに導く、新しいソクラテス問答法</div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--bronze">Bronze</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">WEDU Communications / SM Universe / Tekville</div>
                                        <div class="ahistory__cproduct">K-STEAM School with Metaverse</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Robomation Co.,Ltd.</div>
                                        <div class="ahistory__cproduct">SW and AI learning solution using robots</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--university m-fsize">World Best University</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Smart Diagnosis, Inc.</div>
                                        <div class="ahistory__cproduct">AI-based online exam automatic proctoring SW</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // ahistory__section -->

                    <!-- ahistory__section -->
                    <div class="ahistory__section" data-aos="fade-up" data-aos-duration="1000">
                        <div class="ahistory__title">
                            <div class="ahistory__year">2022</div>
                            <div class="ahistory__ytxt">3rd AES Global Award</div>
                        </div>

                        <div class="ahistory__lists">
                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--platinum">Platinum</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">THE MATCH LAB</div>
                                        <div class="ahistory__cproduct">My Game Tipes (mgt.gg)</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Amazon Japan G.K.</div>
                                        <div class="ahistory__cproduct">Amazon Kids+</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--gold">Gold</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Ai Language Technology Co.,LTD</div>
                                        <div class="ahistory__cproduct">Topics for Award Application Portable All-in-One Teaching Machine</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Riiid Inc</div>
                                        <div class="ahistory__cproduct">AI tutor solution 'Santa'</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Gakusho Co., Ltd.</div>
                                        <div class="ahistory__cproduct">Hodoo English Mobile.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--silver">Silver</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">GLOBE POINT</div>
                                        <div class="ahistory__cproduct">VRWARE StoryBuilder</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Pablo arts Inc.</div>
                                        <div class="ahistory__cproduct">The real-time online art edu-tech ‘ART BONBON’</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Ginger App Company</div>
                                        <div class="ahistory__cproduct">xSTAGE：new LMS fully compliant with xAPI/cmi5</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--bronze">Bronze</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">TRIGIT software</div>
                                        <div class="ahistory__cproduct">Simulation content which uses an interactive video</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Intube Inc.</div>
                                        <div class="ahistory__cproduct">LMS that supports real/non-real-time - interaction</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Divii School, Inc.</div>
                                        <div class="ahistory__cproduct">Divii School Solution</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Herazika Co., Ltd.</div>
                                        <div class="ahistory__cproduct">Online Study Room 2.0</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--university m-fsize">World Best University</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">SKKU EXTENSION SCHOOL</div>
                                        <div class="ahistory__cproduct">Lifelong Education Platform</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // ahistory__section -->

                    <!-- ahistory__section -->
                    <div class="ahistory__section" data-aos="fade-up" data-aos-duration="1000">
                        <div class="ahistory__title">
                            <div class="ahistory__year">2021</div>
                            <div class="ahistory__ytxt">2nd AES Global Award</div>
                        </div>

                        <div class="ahistory__lists">
                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--platinum">Platinum</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Hyundaeyongosa Co., Ltd</div>
                                        <div class="ahistory__cproduct">Speaking Tok</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Gakusho Co., Ltd.</div>
                                        <div class="ahistory__cproduct">Digital drills(Middle School ver.)</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">aichinese-retech</div>
                                        <!-- <div class="ahistory__cproduct"></div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--gold">Gold</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Mingaku Co., Ltd. <span class="m-hide">- Online study room specialized for educational institutions</span></div>
                                        <!-- <div class="ahistory__cproduct">Online study room specialized for educational institutions</div> -->
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">VISANG EDUCATION</div>
                                        <div class="ahistory__cproduct">ELiF</div>
                                    </div> 

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">i-guan</div>
                                    </div>                                    
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--silver">Silver</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Benesse Corporation.</div>
                                        <div class="ahistory__cproduct">Speaking Quest</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">MEDIOPIA TECH Corp.</div>
                                        <div class="ahistory__cproduct">Virtual CAMPUS</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--bronze">Bronze</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">SWEMPIRE Co., Ltd.</div>
                                        <div class="ahistory__cproduct">Blockchain service platform</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">With The World. Inc <span class="m-hide">- Global Online Class for Social Problem Solving for Middle and High School Students</span></div>
                                        <!-- <div class="ahistory__cproduct">Global Online Class for Social Problem Solving for Middle and High School Students</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // ahistory__section -->

                    <!-- ahistory__section -->
                    <div class="ahistory__section" data-aos="fade-up" data-aos-duration="1000">
                        <div class="ahistory__title">
                            <div class="ahistory__year">2020</div>
                            <div class="ahistory__ytxt">1st AES Global Award</div>
                        </div>

                        <div class="ahistory__lists">
                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--platinum">Platinum</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Cisco Systems G.K.</div>
                                        <div class="ahistory__cproduct">Cisco Networking Academy</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">i-Portfolio</div>
                                        <div class="ahistory__cproduct">Digital English Reading Service : Reading &</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--gold">Gold</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Time Education C&P</div>
                                        <div class="ahistory__cproduct">FACTO SCHULE</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Hylable Inc.</div>
                                        <div class="ahistory__cproduct">Visualization of Web Conferences Hylable</div>
                                    </div> 

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">AI ENGLISH PTH LTD</div>
                                        <div class="ahistory__cproduct">AI ENGLISH</div>
                                    </div>                                    
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--silver">Silver</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">SPLYZA Inc.</div>
                                        <div class="ahistory__cproduct">Automated Lecture Video Editor : Lecta</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">YBM NET <span class="m-hide">- America Textbook Electronic Library Program&Learning System : YBM Reading Farm</span></div>
                                        <!-- <div class="ahistory__cproduct">America Textbook Electronic Library Program&Learning System : YBM Reading Farm</div> -->
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">SHENZHEN NEWVANE TECHNOLOGY</div>
                                        <div class="ahistory__cproduct">Mishu</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ahistory__list">
                                <div class="ahistory__aname ahistory__aname--bronze">Bronze</div>

                                <div class="ahistory__items">
                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">Infraware Consortium</div>
                                        <div class="ahistory__cproduct">VRWARE POLARIS BOARD</div>
                                    </div>

                                    <div class="ahistory__item">
                                        <div class="ahistory__cname">QUREO, Inc.</div>
                                        <div class="ahistory__cproduct">QUREO Programming Class</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // ahistory__section -->
                </div>
                <!-- // award-history -->
            </div>
        </section>
        <!-- // n-section -->

        <!-- n-section -->
        <section class="n-section n-section--contact-bg" id="contact">
            <div class="n-section__content n-section__contact">
                <div
                    class="main-title"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                >
                    <h2 class="main-title__h">CONTACT</h2>
                </div>

                <p 
                    class="contact-guide"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                >
                    {{$t('contact.description')}}
                </p>

                <div 
                    class="contact-mail"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                >
                    <div class="contact-mail__container">
                        <div class="contact-mail__title">{{$t('contact.kor')}}</div>

                        <a href="mailto:sangeun.lee1@t-ime.com">
                            <div class="contact-mail__box">
                                <div class="contact-mail__logo">
                                    <img src="../assets/img/contact-logo-kor.png" alt="" class="contact-mail__image contact-mail__image--kor" />
                                </div>

                                <span class="contact-mail__url contact-mail__url--red">sangeun.lee1@t-ime.com</span>

                                <div class="contact-mail__send contact-mail__send--red">Send e-mail</div>
                            </div>
                        </a>
                    </div>

                    <div class="contact-mail__container">
                        <div class="contact-mail__title">{{$t('contact.asian')}}</div>

                        <a href="mailto:david.roh@nl-hd.com">
                            <div class="contact-mail__box">
                                <div class="contact-mail__logo">
                                    <img src="../assets/img/contact-logo-asia.png" alt="" class="contact-mail__image contact-mail__image--asia" />
                                </div>

                                <span class="contact-mail__url contact-mail__url--sky">david.roh@nl-hd.com</span>

                                <div class="contact-mail__send contact-mail__send--sky">Send e-mail</div>
                            </div>
                        </a>
                    </div>

                    <div class="contact-mail__container">
                        <div class="contact-mail__title">{{$t('contact.vn')}}</div>

                        <a href="mailto:htqt@ntu-hn.edu.vn">
                            <div class="contact-mail__box">
                                <div class="contact-mail__logo">
                                    <img src="../assets/img/contact-logo-vn.png" alt="" class="contact-mail__image contact-mail__image--asia" />
                                </div>

                                <span class="contact-mail__url contact-mail__url--blue">htqt@ntu-hn.edu.vn</span>

                                <div class="contact-mail__send contact-mail__send--blue">Send e-mail</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!-- // n-section -->

        <!-- page-top --> 
        <div class="page-top">
            <button class="page-top__button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.481 8.906" class="page-top__svg">
                    <path d="M1137,6765.741l.5.5,1.886-1.886v7.554h.712v-7.554l1.886,1.886.5-.5-2.74-2.74Z" transform="translate(-1137 -6763)"/>
                </svg>
            </button>
        </div>
        <!-- // page-top -->

        <!-- <router-link to="/kor/about">about</router-link> -->
    </div>
    <!-- // home -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import $ from 'jquery';
import ogImage from '../assets/img/og-sns-home.png';
// import ModalAward2022 from '../components/ModalAward2022.vue';
import ModalAward from '../components/ModalAward.vue';
import ModalAward2023 from '../components/ModalAward2023.vue';
import ModalAward2024 from '../components/ModalAward2024.vue';

export default {
    name: 'Home',
    components: {        
        Swiper,
        SwiperSlide, // https://swiperjs.com/swiper-api
        ModalAward,
        ModalAward2023,
        ModalAward2024
        // ModalAward2022,
    },

    metaInfo() {
        return {
            title: 'AES',
            titleTemplate: '%s | ASIA EDTECH SUMMIT', // title 뒤에 붙일 공통된 이름 (주로 사이트이름) 
            // htmlAttrs: {
            //     lang: window.getApp.$i18n.locale // i18n 사용하지 않는 경우 직접 입력
            // },
            meta: [
                // { charset: 'utf-8'},

                // SEO setting
                { name: 'description', content: this.$t('meta.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') }, 
                // { name: 'author', content: this.$t('meta.author') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.og.description'), vmid: 'og:description' },
                
                // mobile
                // { name: 'viewport', content: 'width=device-width, initial-scale=1'}
            ]
        };
    },
    
    data: function() {
        return {
            isOpenNavDrawer: false, // 좌측 서랍형 네비게이션 활성화
            isPopupAward: false, // 2021 어워드 팝업 활성화
            activePopup: true, // 어워드 팝업 활성화
            activePopup2023: true,

            langValue: '',
            langList: [],
            visible: false,

            swiperOption: {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                slidesPerView: 1,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        var menu = ['Korea', 'Japan', 'China', 'Vietnam'];
                        return '<span class="' + className + '">' + (menu[index]) + '</span>';
                    },
                },
            }
        };
    },

    created() {
        // console.log('lang: ', this.$i18n.locale);
        console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
        // let locale = navigator.language || navigator.userLanguage;
        // locale = locale.substring(0, 2);
        // if (locale !== 'ko') locale = 'en'; // 한국어가 아닌 경우 무조건 영어로 설정
        // this.$i18n.locale = locale;
        // this.$i18n.locale = 'ko';

        // const html = document.documentElement;
        // html.setAttribute('lang', 'ko');
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        var url = window.location.pathname;
        var urlSplit = url.split('/'); // "/" 로 전체 url 을 나눈다
        var lang = urlSplit[1];
        // var nArLength = arSplitUrl.length;
        // var arFileName = arSplitUrl[nArLength-1]; // 나누어진 배열의 맨 끝이 파일명이다
        // var arSplitFileName = arFileName.split('.'); // 파일명을 다시 "." 로 나누면 파일이름과 확장자로 나뉜다
        // var sFileName = arSplitFileName[0]; // 파일이름
        // var sFileExtension = arSplitFileName[1]; // 확장자

        // console.log(lang);
        
        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        // let lang = urlParams.get('lang');
        lang = lang ? lang.toLocaleUpperCase() : 'ENG';

        this.langValue = lang;

        if (lang == 'KOR') {
            this.$i18n.locale = 'ko';
        } else if (lang == 'ENG') {
            this.$i18n.locale = 'en';
        } else if (lang == 'JP') {
            this.$i18n.locale = 'ja';
        } else {
            this.$i18n.locale = 'ch';
        }
        
        this.moveMenu();
        this.moveTop();
        this.setLangHtml(lang);
        this.handleScroll();
        this.checkLang(lang); // 언어체크후, 팝업 표시 여부 처리
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        /* ============================================================
        * 메뉴 클릭시 스크롤 이동
        * ============================================================ */
        moveMenu() {
            $('.menu__button').on('click', (event) => {
                event.preventDefault();
                $('html,body').animate({scrollTop:$(event.target.hash).offset().top}, 500);
                this.closeNavDrawer();
            });     
        },

        /* ============================================================
        * window 이벤트
        * ============================================================ */
        handleResize() {
            // $('.page-top').fadeOut();
            // window.removeEventListener('scroll', this.handleScroll);
            if (window.innerWidth >= 1180) {
                // $('.page-top').fadeIn();
                // window.addEventListener('scroll', this.handleScroll);
                this.closeNavDrawer();            
                // this.moveTop();
            } else {
                // $('.page-top').fadeOut();
            }
        },

        handleScroll() {
            // var val = $(document).height() - $(window).height() - $('.footer').outerHeight();
            // console.log($('.footer').outerHeight());
            // this.handleResize(); 

            $(window).scroll(function() {                
                if ($(this).scrollTop() > 250) { // 250 넘으면 버튼이 보여짐니다.
                    $('.page-top').fadeIn();
                    // $('.page-top').css('left', $('#sidebar').offset().left);  // #sidebar left:0 죄표
                } else {
                    $('.page-top').fadeOut();
                }

                // if ($(this).scrollTop() >= val) {
                //     $('.page-top').css('bottom', $('.footer').outerHeight());
                // } else {
                //     $('.page-top').css('bottom', '20px');
                // }
            });
        },

        /* ============================================================
        * Top 버튼 스크롤 이동
        * ============================================================ */
        moveTop() {
            $('.page-top').click(function() {
                $('html, body').animate({
                    scrollTop : 0
                }, 400);
                return false;
            });
        },

        /* ============================================================
        * 좌측 서랍형 네비게이션
        * ============================================================ */
        openNavDrawer() {
            this.isOpenNavDrawer = true;
        },

        closeNavDrawer() {
            this.isOpenNavDrawer = false;
        },

        toggleNavDrawer() {
            if (this.isOpenNavDrawer) {
                this.closeNavDrawer();
            } else {
                this.openNavDrawer();
            }
        },

        /* ============================================================
        * 언어 설정
        * ============================================================ */
        setLangHtml(type) {
            if (type == 'KOR') {
                type= 'ko';
            } else if (type == 'ENG') {
                type = 'en';
            } else if (type == 'JP') {
                type = 'ja';
            } else if (type == 'CHI') {
                type = 'zh';
            }

            const html = document.documentElement;
            html.setAttribute('lang', type);
        },
        
        setLangList() {            
            const list = ['KOR','ENG','JP', 'CHI'];
            this.langList = list.filter((e) => e != this.langValue);
        },       

        toggleLang() {
            this.visible = !this.visible;
            this.setLangList();
        },
        
        selectLang(option) {
            console.log('sleectLang option : ', option);
            // document.location.href = `/?lang=${ option.toLowerCase() }`;
            document.location.href = `/${ option.toLowerCase() }`;
        },
        
        /* ============================================================
        * Popup
        * ============================================================ */
        closePopup() {
            this.activePopup = false;
        },

        checkLang(lang) {
            if (lang == 'ENG' || lang == 'KOR') {
                this.activePopup = true;
            } else {
                this.activePopup = false;
            }
        },
    }
};
</script>