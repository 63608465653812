<template>
    <div class="popup-wrap" v-if="activePopup">
        <div class="popup-dim"></div>
        
        <div class="popup-base">
            <div class="popup-scroll">
                <img :src="require(`@/assets/img/${ $t('popupAward2024.img') }`)" />

                <button class="close-award" @click="closePopup">
                    close
                </button>

                <a :href="$t('popupAward2024.link')" target="_blank" class="download-link">{{ $t('popupAward2024.text') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal-award-3',
    
    props : [
        'activePopup',
    ],

    mounted() {
        //
    },

    methods: {
        closePopup: function () {
            this.$emit('closePopup');
        }
    }
};
</script>
