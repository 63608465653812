<template>
    <!-- not-found-page --> 
    <div class="not-found-page" v-cloak>
        <i aria-hidden="true" class="n-icon n-icon-not-found">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.384 72.523">
                <g id="그룹_5" data-name="그룹 5" transform="translate(201.384 159.867)">
                    <g id="그룹_3" data-name="그룹 3" transform="translate(-201.384 -159.867)">
                        <path id="패스_1" data-name="패스 1" d="M-108.706-84.448l-30.663-53.114A7.094,7.094,0,0,0-145.5-141.1a7.1,7.1,0,0,0-6.136,3.541L-182.3-84.448a7.064,7.064,0,0,0,0,7.079,7.089,7.089,0,0,0,6.136,3.541h61.325a7.1,7.1,0,0,0,6.136-3.541A7.066,7.066,0,0,0-108.706-84.448Z" transform="translate(186.197 144.149)" fill="#fff"/>
                        <g id="그룹_2" data-name="그룹 2" transform="translate(0 0)">
                            <g id="그룹_1" data-name="그룹 1">
                                <path id="패스_2" data-name="패스 2" d="M-160.692-152.233l33.056,57.255h-66.112l33.056-57.255m0-7.634a7.641,7.641,0,0,0-6.613,3.817l-33.056,57.255a7.631,7.631,0,0,0,0,7.634,7.64,7.64,0,0,0,6.613,3.817h66.112a7.64,7.64,0,0,0,6.613-3.817,7.629,7.629,0,0,0,0-7.634l-33.056-57.255a7.641,7.641,0,0,0-6.613-3.817Z" transform="translate(201.384 159.867)" fill="#d0293d"/>
                            </g>
                        </g>
                    </g>
                    <g id="그룹_4" data-name="그룹 4" transform="translate(-164.812 -138.374)">
                        <path id="패스_3" data-name="패스 3" d="M31.91-1.445A3.049,3.049,0,0,1,28.861,1.6h-.688a3.049,3.049,0,0,1-3.049-3.049L24.27-24.407a3.049,3.049,0,0,1,3.049-3.049h2.03A3.049,3.049,0,0,1,32.4-24.407Z" transform="translate(-24.214 27.456)" fill="#d0293d"/>
                        <path id="패스_4" data-name="패스 4" d="M32.167,172.845a4.12,4.12,0,1,1-4.12-4.12A4.12,4.12,0,0,1,32.167,172.845Z" transform="translate(-23.928 -136.881)" fill="#d0293d"/>
                    </g>
                </g>
            </svg>
        </i> 

        <div class="not-found-text">Page<br class="m-show" /> Not<br />Found:(</div>

        <button class="not-found-button" @click="goHome">Back to home</button>
    </div>
    <!-- // not-found-page -->
</template>

<script>
import ogImage from '../assets/img/og-sns-home.png';

export default {
    name: 'NotFoundPage',
    components: {        
        // Swiper,
    },
    metaInfo() {
        return {
            title: 'Page Not Found',
            titleTemplate: '%s | ASIA EDTECH SUMMIT', // title 뒤에 붙일 공통된 이름 (주로 사이트이름) 
            // htmlAttrs: {
            //     lang: window.getApp.$i18n.locale // i18n 사용하지 않는 경우 직접 입력
            // },
            meta: [
                // { charset: 'utf-8'},

                // SEO setting
                { name: 'description', content: this.$t('meta.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') }, 
                // { name: 'author', content: this.$t('meta.author') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.og.description'), vmid: 'og:description' },
                
                // mobile
                // { name: 'viewport', content: 'width=device-width, initial-scale=1'}
            ]
        };
    },

    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        window.addEventListener('resize', this.handleResize); 

        // this.handleResize();

        // this.$nextTick(() => {
        // });

        setTimeout(() =>{
            this.handleResize();
        },0);
    },

    methods: {
        /* ============================================================
        * window 이벤트
        * ============================================================ */
        handleResize() {
            const footerDiv = document.querySelector('.footer');
            const notFoundDiv = document.querySelector('.not-found-page');
            const footerHeight = footerDiv.offsetHeight;

            notFoundDiv.style.height=`calc(100vh - ${footerHeight}px)`;
        },

        /* ============================================================
        * Back to home 버튼
        * ============================================================ */
        goHome() {
            console.log('ddd');
            var url = window.location.pathname;
            var urlSplit = url.split('/'); // "/" 로 전체 url 을 나눈다
            var lang = urlSplit[1];

            if(lang == 'kor' || lang == 'eng' || lang =='jp' || lang =='chi') {
                this.$router.push(`/${lang}`);
            } else {
                this.$router.push('/');
            }
        }
    }
};
</script>